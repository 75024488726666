import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { v4 as uuidv4 } from "uuid";
import Styled from "styled-components";

import Hamburger from "./Hamburger";
import { ROUTES } from "@config";

const StyledMenu = Styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-color: ${({ theme }) => theme.black};
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
    height: 100vh;
    text-align: left;
    padding: 2rem;
    position: absolute;
    top: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;
    a {
        margin: 0px;
        font-size: 2rem;
        text-transform: uppercase;
        padding: 2rem 0;
        font-weight: bold;
        letter-spacing: 0.5rem;
        color: ${({ theme }) => theme.white};
        text-decoration: none;
        transition: color 0.3s linear;
        @media (max-width: 576px) {
            font-size: 1.5rem;
            text-align: center;
        }
        &:hover {
            color: ${({ theme }) => theme.action};
        }
    }
    @media (max-width: 576px) {
        width: 100%;
    }
`;
const Logo = Styled(Img)`
    width: 200px;
`;

const Menu = ({ isOpen }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo_white_600x425.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const logo = data.logo.childImageSharp.fluid;
  return (
    <StyledMenu open={isOpen}>
      <Link to="/">
        <Logo fluid={logo} alt="Cassandra Lee & Co" objectFit="contain" />
      </Link>
      {ROUTES.map(({ path, name }) => (
        <Link key={uuidv4()} to={path}>
          {name}
        </Link>
      ))}
    </StyledMenu>
  );
};

export { Menu, Hamburger };
