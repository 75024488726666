import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
    }
    *, *::after, *::before {
        box-sizing: border-box;
    }
    body {
        background-color: ${({ theme }) => theme.white};
        color: ${({ theme }) => theme.black};
        text-rendering: optimizeLegibility;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        background-color: transparent;
        color: rgb(71, 71, 71);
        border: ${({ theme }) => `1px solid ${theme.black}`};
        -webkit-text-fill-color: rgb(71, 71, 71);
        transition: background-color 5000s ease-in-out 0s;
    }
`;

export default GlobalStyles;
