import { useEffect } from "react";

// const ref = useRef();
//? State for our modal
// const [isModalOpen, setModalOpen] = useState(false);
//? Call hook passing in the ref and a function to call on outside click
// useOnClickOutside(ref, () => setModalOpen(false));

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
