import React from "react";

import Top from "./Top";
import Bottom from "./Bottom";

const Footer = ({ map }) => (
  <>
    <Top map={map} />
    <Bottom />
  </>
);

export default Footer;
