import React from "react";
import { ThemeProvider } from "styled-components";
import { Normalize } from "styled-normalize";
import SEO from "@components/SEO";
import Nav from "@components/Nav";
import Header from "@components/Header";
import Footer from "@components/Footer";
// Styled Components Theme/Global
import { GlobalStyles, Theme, Fonts } from "../../styles";

const Layout = ({ seoTitle, seoDescription, nav = true, header = true, map = false, fluid, alt, headerTitle, headerSubTitle, children }) => (
  <ThemeProvider theme={Theme}>
    <Normalize />
    <GlobalStyles />
    <Fonts />
    <SEO title={seoTitle} description={seoDescription} />
    {nav && <Nav />}
    {header && <Header fluid={fluid} alt={alt} title={headerTitle} subTitle={headerSubTitle} />}
    <main>{children}</main>
    <Footer map={map} />
  </ThemeProvider>
);

export default Layout;
