import React from "react";
import { navigate } from "gatsby";
import Styled from "styled-components";

const MyButton = Styled.button`
    ${({ gridArea }) => (gridArea ? `grid-area: ${gridArea}` : null)};
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: ${({ theme, dark }) => (dark ? `2px solid ${theme.white}` : `2px solid ${theme.black}`)};
    color: ${({ theme, dark }) => (dark ? theme.white : theme.black)};
    cursor: pointer;
    display: flex;
    align-self: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 1rem 0;
    padding: 1rem 2rem;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fontPrimary};
    font-weight: 700;
    &:hover {
        color: ${({ theme, dark }) => (dark ? theme.black : theme.white)};
        border: ${({ theme, dark }) => (dark ? `2px solid ${theme.white}` : `2px solid ${theme.black}`)};
    }
    &:focus,
    &:active  {
        color: ${({ theme, dark }) => (dark ? theme.black : theme.black)};
        border: ${({ theme, dark }) => (dark ? `2px solid ${theme.black}` : `2px solid ${theme.black}`)};
        outline: 0;
    }
    transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
    &:hover {
        box-shadow: ${({ theme, dark }) => (dark ? `0 0 40px 40px ${theme.white} inset` : `0 0 40px 40px ${theme.action} inset`)};
    }
`;

const Button = ({ children, to, onClick, type = "button", dark = false, gridArea = null }) => {
  if (onClick) {
    return (
      <MyButton type={type} onClick={onClick} dark={dark} gridArea={gridArea}>
        {children}
      </MyButton>
    );
  }
  return (
    <MyButton type={type} onClick={() => navigate(to)} dark={dark} gridArea={gridArea}>
      {children}
    </MyButton>
  );
};

export default Button;
