import React from "react";
import Styled from "styled-components";

const MyBottom = Styled.footer`
    width: 100vw;
    height: 7rem;
    background-color: ${({ theme }) => theme.black};
    color: ${({ theme }) => theme.white};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ul {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        li {
            margin-left: 1rem;
            margin-right: 1rem;
            color: ${({ theme }) => theme.white};
            font-family: ${({ theme }) => theme.fontSecondary};
            font-size: 0.75rem;
            a {
                color: ${({ theme }) => theme.white};
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
`;

const Bottom = () => (
  <MyBottom>
    <ul>
      <li>&copy; {new Date().getFullYear()} Cassandra &amp; Co All rights reserved.</li>
    </ul>
  </MyBottom>
);

export default Bottom;
