import React from "react";
import Styled from "styled-components";

const A = Styled.a`
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
`;
const ExternalLink = ({ key, className, href, children, target = "_blank" }) => (
  <A key={key} className={className} href={href} rel="noopener noreferrer" target={target}>
    {children}
  </A>
);

export default ExternalLink;
