import React from "react";
import Styled from "styled-components";

import Button from "@components/Button";
import ExternalLink from "@components/ExternalLink";
import Facebook from "./Facebook";
import Instagram from "./Instagram";
import MyMap from "@components/MyMap";

const MyTop = Styled.footer`
    width: 100vw;
    height: 28rem;
    background-color: ${({ theme }) => theme.gainsboro};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
        color: ${({ theme }) => theme.black};
        font-family: ${({ theme }) => theme.primary};
        font-size: 2.75rem;
        margin: 0px;
    }
    hr {
        margin-top: 1rem;
        width: 50px;
    }
`;
const Social = Styled.div`
    display: flex;
    flex-direction: row;
`;

const Top = ({ map }) => {
  if (map) {
    return <MyMap />;
  }
  return (
    <MyTop>
      <h2>Connect With Me</h2>
      <hr />
      <Button to={"/contact"}>Contact</Button>
      <Social>
        <ExternalLink href={`https://www.facebook.com/Cassandra.Lee.Realtor/`}>
          <Facebook />
        </ExternalLink>
        <ExternalLink href={`https://www.instagram.com/cassandra.stgrealtor/`}>
          <Instagram />
        </ExternalLink>
      </Social>
    </MyTop>
  );
};

export default Top;
