import React from "react";
import Img from "gatsby-image";
import Styled from "styled-components";

import ExternalLink from "@components/ExternalLink";

const Header = Styled.header`
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
`;
const BgImage = Styled(Img)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${({ height }) => (height ? `${height}px` : `500px`)};
    z-index: -1;
    & > img {
        object-fit: cover !important;
        object-position: 0% 0% !important;
        font-family: 'object-fit: cover !important; object-position: 0% 0% !important;';
    }
    @media screen and (max-width: 600px) {
        height: ${({ mobileHeight }) => (mobileHeight ? `${mobileHeight}px` : `350px`)};
    }
`;
const Content = Styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Title = Styled.h1`
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: 4rem;
    margin: 0px;
    font-weight: 400;
    overflow-wrap: break-word;
    color: ${({ theme }) => theme.white};
    @media screen and (max-width: 600px) {
        text-align: center;
        font-size: 2.75rem;
    }
`;
const SubTitle = Styled.h2`
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: 4rem;
    margin: 0px;
    font-weight: 400;
    overflow-wrap: break-word;
    color: ${({ theme }) => theme.white};
    @media screen and (max-width: 600px) {
        text-align: center;
        font-size: 2.75rem;
    }
`;
const CTA = Styled.h3`
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: 1.25rem;
    overflow-wrap: break-word;
    color: ${({ theme }) => theme.white};
    position: absolute;
    bottom: 20px;
    a {
        color: ${({ theme }) => theme.white};
        text-decoration: none;
        &:hover {
            color: ${({ theme }) => theme.action};
        }
    }
`;

const MyHeader = ({ fluid, alt, height, mobileHeight, title, subTitle }) => {
  return (
    <Header>
      <BgImage fluid={fluid} alt={alt} height={height} mobileHeight={mobileHeight} />
      <Content>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
        <CTA>
          Cassandra Lee <ExternalLink href="tel:4352726097">435-272-6097</ExternalLink>
        </CTA>
      </Content>
    </Header>
  );
};

export default MyHeader;
