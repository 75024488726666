import React from "react";
import Styled from "styled-components";

const StyledBurger = Styled.button`
    position: absolute;
    top: 0.5rem;
    right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    &:focus {
        outline: none;
    }
    div {
        width: 2rem;
        height: 0.25rem;
        background: ${({ open, theme }) => (open ? theme.white : theme.white)};
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
        &:first-child {
            transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
        }
        &:nth-child(2) {
            opacity: ${({ open }) => (open ? "0" : "1")};
            transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
        }
        &:nth-child(3) {
            transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
        }
    }
`;

const Hamburger = ({ isOpen, setIsOpen }) => (
  <StyledBurger open={isOpen} onClick={() => setIsOpen(!isOpen)}>
    <div />
    <div />
    <div />
  </StyledBurger>
);

export default Hamburger;
