import React, { useState } from "react"
import { Map, Marker, GoogleApiWrapper } from "google-maps-react"

const MapContainer = ({ google }) => {
  const [location] = useState({
    address: "90 East 100 S Saint George, Utah 84770, United States",
    lat: 37.10632,
    lng: -113.58142,
  })
  return (
    <Map
      google={google}
      zoom={10}
      style={{
        width: "100%",
        height: "100%",
      }}
      initialCenter={{
        lat: location.lat,
        lng: location.lng,
      }}
    >
      <Marker
        title={location.address}
        name={"Cassandra Lee"}
        position={{ lat: location.lat, lng: location.lng }}
      />
    </Map>
  )
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyB6x_NqdvZ_oY3i-GlAx-5UCsmaWezz4O4",
})(MapContainer)
