import React, { useState, useRef } from "react";
import { Link } from "gatsby";
import { v4 as uuidv4 } from "uuid";
import Styled, { css } from "styled-components";

import { Menu, Hamburger } from "@components/Menu";
import { useScrollPosition, useOnClickOutside } from "@hooks";
import { ROUTES } from "@config";

const Floating = css`
  background-color: rgba(22, 22, 22, 0.9);
  border-radius: 2px;
  box-shadow: 0px 1px 10px rgba(255, 255, 255, 0.5);
`;
const Initial = css`
  background-color: transparent;
`;
const Nav = Styled.nav`
    position: fixed;
    z-index: 10;
    width: 100vw;
    transition: 0.2s;
    ${({ hasScrolled }) => (hasScrolled ? Floating : Initial)};
    transition: all 500ms linear;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
const Ul = Styled.ul`
    padding-top: 1rem;
    padding-bottom: 1rem;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    padding: 0px;
    li {
        ${({ isLeft }) => (isLeft ? `margin-left: 1rem;` : `margin-right: 1rem`)};
        a {
            font-size: ${({ isLogo }) => (isLogo ? `1.5rem` : `0.85rem`)};
            letter-spacing: 0.071em;
            text-decoration: none;
            font-family: ${({ theme }) => theme.fontSecondary};
            color: ${({ theme }) => theme.white};
            &:hover {
                color: ${({ theme }) => theme.action};
            }
        }
        @media screen and (max-width: 600px) {
            a {
                /* for name/logo */
                font-size: 1rem;
                margin-left: 2rem;
            }
        }
    }
    /* hide on mobile and reconstruct hamburger */
    @media screen and (max-width: 600px) {
        ${({ isLogo }) => (!isLogo ? `display: none` : null)};
    }
`;
const HamContainer = Styled.div`
    display: none;
    @media screen and (max-width: 600px) {
        display: flex;
    }
`;

const MyNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef();
  const [hasScrolled, setHasScrolled] = useState(false);
  useOnClickOutside(menuRef, () => {
    setIsOpen(false);
  });

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y < -100 && !hasScrolled) {
        setHasScrolled(true);
      } else if (currPos.y > -80) {
        setHasScrolled(false);
      }
    },
    [hasScrolled]
  );

  return (
    <Nav hasScrolled={hasScrolled}>
      <Ul isLeft>
        {ROUTES.map(({ path, name, left }) => {
          if (left) {
            return (
              <li key={uuidv4()}>
                <Link to={path}>{name}</Link>
              </li>
            );
          }
          return null;
        })}
      </Ul>
      <Ul isLogo>
        <li>
          <Link to="/">Cassandra Lee &amp; Co</Link>
        </li>
      </Ul>
      <Ul>
        {ROUTES.map(({ path, name, left }) => {
          if (!left) {
            return (
              <li key={uuidv4()}>
                <Link to={path}>{name}</Link>
              </li>
            );
          }
          return null;
        })}
      </Ul>
      <HamContainer ref={menuRef}>
        <Hamburger isOpen={isOpen} setIsOpen={setIsOpen} />
        <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
      </HamContainer>
    </Nav>
  );
};

export default MyNav;
