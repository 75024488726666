const DEV = false;
export const SERVERLESS_URL = DEV ? "http://localhost:9000/.netlify/functions" : "/.netlify/functions";

export const ROUTES = [
  {
    name: "Active Listings",
    path: "/listings",
    left: true,
  },
  {
    name: "Testimonials",
    path: "/testimonials",
    left: true,
  },
  {
    name: "About",
    path: "/about",
    left: false,
  },
  {
    name: "Contact",
    path: "/contact",
    left: false,
  },
];

export const LISTINGS = {
  "21-219873": {
    price: "$3,521,000",
    address: "1610 Granite Way",
    city: "St. George",
    state: "UT",
    zip: "84790",
  },
  "21-219870": {
    price: "$2,632,000",
    address: "1616 Shale Circle",
    city: "St. George",
    state: "UT",
    zip: "84790",
  },
  "20-219162": {
    price: "$1,550,000",
    address: "2144 N Anasazi Trail Lot 61",
    city: "St. George",
    state: "UT",
    zip: "84770",
  },
  "20-210243": {
    price: "$1,425,000",
    address: "2415 Anasazi Trail",
    city: "St. George",
    state: "UT",
    zip: "84770",
  },
  "21-220084": {
    price: "$2,599,000",
    address: "2097 Cliff Point Drive",
    city: "St. George",
    state: "UT",
    zip: "84790",
  },
  "20-217049": {
    price: "$3,490,000",
    address: "1901 E Jade Drive",
    city: "St. George",
    state: "UT",
    zip: "84790",
  },
  "21-219793": {
    price: "$1,500,000",
    address: "2027 Pinnacle Circle",
    city: "St. George",
    state: "UT",
    zip: "84790",
  },
  "20-218512": {
    price: "$1,275,000",
    address: "2389 N Tavimaus Circle",
    city: "St. George",
    state: "UT",
    zip: "84770",
  },
  "21-220178": {
    price: "$4,500,000",
    address: "1708 S View Point",
    city: "St. George",
    state: "UT",
    zip: "84790",
  },
};

export const TESTIMONIALS = [
  {
    name: "Mary",
    description:
      "Without Cassandra as my realtor, I would've never been able to become a first time buyer. She made it possible for me as a single mom.",
  },
  { name: "Bradley", description: "Finding the right home to raise my family felt like a challenge. Cassandra made that happen with ease!" },
  { name: "Alex", description: "Home is not just a state of mind. It's a process, and Cassandra led the way." },
  { name: "Robert", description: "Cassandra is our agent period, no need to say more." },
  {
    name: "Joe",
    description:
      "We were from out of the state and all the homes that we're in our price range were gone before we could even get to Saint George. Cassandra was able to fight on our behalf to be able to relocate out here in time to start my new job.",
  },
  { name: "Celia", description: "Born and raised an urbanite, I was pretty picky. Cassandra was perfect." },
  {
    name: "Nolan",
    description:
      "I have worked with several agents. I am an investor and a huge pain to deal with. I take my business seriously and I will never use another agent again enough said.",
  },
  { name: "Elisa", description: "She is the best in the business." },
  {
    name: "Kristin",
    description:
      "Cassandra is the best realtor! She works hard to make sure her clients are well taken care of by listening to their needs and answering questions. Being very knowledgeable in her field, Cassandra has clientele that continually comes back for their buying/selling needs. Her patience and trust are some attributes you don't just find with every realtor out in the market. I will continually refer her knowing she gets the job done with a smile and loves what she does. You'll be happy you chose Cassandra to help you along the way!",
  },
  {
    name: "Cheri",
    description:
      "Cassandra is the most patient and understanding realtor I have ever worked with. I'm an extremely picky person and she was always more than willing to take so much time and energy dealing with me and my husband. Thank you Cassandra!",
  },
  {
    name: "Karla",
    description:
      "I highly recommend Cassandra!  She sees you through the entire process, from beginning to end. And works diligently to keep your best interest as her highest priority, very knowledgeable and professional.  Such a pleasure to work with!",
  },
  {
    name: "Cristy",
    description:
      "Cassandra is an amazing realtor! She is very knowledgable with the market in Southern Utah and always has her clients best interests in mind.  I love working with her, and have never had one of her borrowers that didn't absolutely love her! You will not be disappointed by choosing Cassandra as your realtor!",
  },
  {
    name: "Melinda",
    description:
      "Cassandra is a wonderful realtor! She is very professional, cares tremendously for her clients, and pays attention to every tiny detail. You can tell that she really loves what she does because she's amazing at it. Thank you Cassandra!",
  },
  {
    name: "Nannette",
    description:
      "Cassandra was great to work with! She is professional and personable. She helped us through every step in the process of purchasing our home.",
  },
  {
    name: "Shaylee",
    description:
      "Cassandra made it so easy finding our home and really listened to what we wanted to show up the best homes possible for us! I highly recommend her!",
  },
  {
    name: "Stacie",
    description:
      "I'm a lender in Salt Lake City...when I have clients who want to purchase in St. George I always refer them to Cassandra. She does an excellent job, has high level of communication, and always has clients best interests in mind.",
  },
  {
    name: "Adam",
    description:
      "Viewing and buying a house is extremely stressful with a ton of questions. We had certain requests and Cassandra found us exactly what we wanted without taking us to homes that didn't fit what we were looking for! Thank you so much for being patient we will definitely be in touch when we sell and buy again!",
  },
  {
    name: "Lacee",
    description:
      "This lady is the best! She was by our side through the whole thing. Every every single question we had she answered with a smile on her face 😊 I have referred other friends to Cassandra because I know she will take care of them. We will move again and we will have Cassandra be our realtor for sure!!!",
  },
  {
    name: "Todd",
    description:
      "Cassandra found the property we wanted and worked so hard to get it for us at the price we felt was fair! She will help you all along the way and put your best interest first!",
  },
  {
    name: "Nicholas",
    description: "First time home buyer. Cassandra helped me find a good home and figure out all the unknowns. Definitely recommend from this guy.",
  },
  {
    name: "Mark",
    description:
      "Cassandra is a rock star! She sold our home in Santa Clara quickly and for full asking price! I highly recommend Cassandra to any one looking to buy or sell a home in Southern Utah.",
  },
  {
    name: "Halee",
    description:
      "Cassandra was so great at being available at anytime, knowing exactly what we wanted & finding it for us,  following up with us to make sure we were happy with our purchase.",
  },
];

export const SIZE = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tabletS: "480px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const DEVICES = {
  maxMobileS: `(max-width: ${SIZE.mobileS})`,
  maxMobileM: `(max-width: ${SIZE.mobileM})`,
  maxMobileL: `(max-width: ${SIZE.mobileL})`,
  maxTabletS: `(max-width: ${SIZE.tabletS})`,
  maxTablet: `(max-width: ${SIZE.tablet})`,
  maxLaptop: `(max-width: ${SIZE.laptop})`,
  maxLaptopL: `(max-width: ${SIZE.laptopL})`,
  maxDesktop: `(max-width: ${SIZE.desktop})`,
  maxDesktopL: `(max-width: ${SIZE.desktop})`,
  minMobileS: `(min-width: ${SIZE.mobileS})`,
  minMobileM: `(min-width: ${SIZE.mobileM})`,
  minMobileL: `(min-width: ${SIZE.mobileL})`,
  minTabletS: `(min-width: ${SIZE.tabletS})`,
  minTablet: `(min-width: ${SIZE.tablet})`,
  minLaptop: `(min-width: ${SIZE.laptop})`,
  minLaptopL: `(min-width: ${SIZE.laptopL})`,
  minDesktop: `(min-width: ${SIZE.desktop})`,
  minDesktopL: `(min-width: ${SIZE.desktop})`,
};
