import { createGlobalStyle } from "styled-components";

import PoppinsRegular from "./PoppinsRegular.ttf";
import LeagueSpartanBold from "./LeagueSpartanBold.otf";
import MerriweatherRegular from "./MerriweatherRegular.ttf";

const Fonts = createGlobalStyle`
    @font-face {
        font-family: 'LeagueSpartanBold';
        src: local('LeagueSpartanBold'), local('LeagueSpartanBold'),
        url(${LeagueSpartanBold}) format('opentype');
    }
    @font-face {
        font-family: 'PoppinsRegular';
        src: local('PoppinsRegular'), local('PoppinsRegular'),
        url(${PoppinsRegular}) format('truetype');
    }
    @font-face {
        font-family: 'MerriweatherRegular';
        src: local('MerriweatherRegular'), local('MerriweatherRegular'),
        url(${MerriweatherRegular}) format('truetype');
    }
`;

export default Fonts;
