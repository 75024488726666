import Styled from "styled-components";

const Section = Styled.section`
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    width: 100vw;
    background-color: ${({ theme, dark }) => (dark ? theme.gainsboro : theme.white)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
        text-align: center;
        margin-top: 2.5rem;
        color: ${({ theme }) => theme.black};
        font-family: ${({ theme }) => theme.primary};
        font-size: 2.75rem;
        margin-bottom: 0px;
    }
    hr {
        margin-top: 1rem;
        width: 50px;
        margin-bottom: 3.5rem;
    }
`;

export { Section };
